<template>
    <div>
        
        <Header></Header>
       <slot></slot>
        <Footer></Footer>
    </div>
</template>
 <script setup lang="ts">

 

</script>